<template>
  <div class="page-wrapper">
    <div class="auth-bg-disabled">
      <div class="authentication-box">

        <div class="text-center m-t-40">
          <router-link to="/" class="logo">
            <img src="../../assets/images/gurumandala-logo.png" alt="">
          </router-link>
        </div>

        <div class="card btn-square mt-4 p-4">
          <h4 class="text-center font-success m-t-10">REGISTRATION COMPLETE</h4>
          <h6 class="text-center font-danger">You will be notified upon account activation</h6>
          <a href="https://classroom.gurumandala.lk/login" class="btn btn-square btn-primary btn-block m-t-10">Login</a>

        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "registered"
}
</script>

<style scoped>

</style>
